import { graphql } from "gatsby";
import React from "react";
import BlockContent from "../components/BlockContent";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

const About = ({
  data: {
    sanityAbout: {
      mainHeading,
      text1,
      helpHeading1,
      helpText1,
      helpHeading2,
      helpText2,
    },
  },
}) => {
  return (
    <>
      <Nav />

      <div className="px-8">
        <div className="text-heading-4 md:text-heading-2 text-gray-dark mb-2 py-8">
          {mainHeading}
        </div>
        <div>
          <BlockContent blocks={text1} />
        </div>

        <div className="text-heading-4 py-8">{helpHeading1}</div>

        <div>
          <BlockContent blocks={helpText1} />
        </div>

        <div className="text-heading-4 py-8">{helpHeading2}</div>

        <div>
          <BlockContent blocks={helpText2} />
        </div>
      </div>
      <Footer />
      <CookieBanner />
    </>
  );
};

export const query = graphql`
  query {
    sanityAbout(_id: { regex: "/about$/" }) {
      mainHeading
      text1: _rawText1
      helpHeading1
      helpText1: _rawHelpText1
      helpHeading2
      helpText2: _rawHelpText2
    }
  }
`;

export default About;
